import {
  offset,
  flip,
  shift,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  useTransitionStyles,
  FloatingArrow,
  arrow,
  Placement
} from '@floating-ui/react'
import { ReactNode, useRef } from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io'
import cn, { Argument } from 'classnames'
import { Portal, useWithPopper } from 'simple-core-ui'
import s from './Tooltip.scss'

type OptionalProps = Partial<{
  trigger: ReactNode
  triggerClassName: Argument
  contentClassName: Argument
  placement: Placement
  testId: string
  hasArrow: boolean
  offset: number
}>

type RequiredProps = {
  content: ReactNode
}

type Props = RequiredProps & OptionalProps

const Tooltip = ({
  trigger,
  triggerClassName,
  content,
  contentClassName,
  placement = 'top',
  testId,
  hasArrow = true,
  offset: offsetValue = 10
}: Props) => {
  const arrowRef = useRef(null)

  const middleware = [offset(offsetValue), flip(), shift()]
  if (hasArrow) {
    middleware.push(
      arrow({
        element: arrowRef
      })
    )
  }

  const { floatingStyles, setReference, setFloating, context } = useWithPopper({
    middleware,
    placement,
    handleState: true
  })

  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, { role: 'tooltip' })

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, focus, dismiss, role])

  const transition = useTransitionStyles(context, {
    initial: { opacity: 0 },
    open: { opacity: 1 }
  })

  return (
    <>
      <span
        data-testid={testId}
        ref={setReference}
        {...getReferenceProps({ className: cn(s.trigger, triggerClassName) })}
      >
        {trigger || <IoIosInformationCircleOutline />}
      </span>
      <Portal>
        {transition.isMounted && (
          <div
            ref={setFloating}
            style={{
              ...transition.styles,
              ...floatingStyles
            }}
            {...getFloatingProps({
              className: cn(s.content, { [s.text]: typeof content === 'string' }, contentClassName)
            })}
          >
            {hasArrow && <FloatingArrow ref={arrowRef} context={context} fill="#FFF" />}
            {content}
          </div>
        )}
      </Portal>
    </>
  )
}

export default Tooltip
