const Users = () => {
  return (
    <svg version="1.1" id="Layer_1" x="0px" viewBox="0 0 128 128">
      <path
        className="st0"
        d="M64.1,17.8c3.1,0,6.2,0.6,9.4,1.8c3.1,1.2,5.9,2.8,8.4,4.7c2.4,1.9,4.4,4.1,5.9,6.6s2.3,4.9,2.3,7.2v14.4 c0,4.2-0.9,8.8-2.7,13.8s-4.5,8.9-8.1,11.7l25.1,12l0.5,0.2c0.4,0.2,0.9,0.4,1.4,0.8s1.1,0.8,1.7,1.3c0.5,0.5,1.1,1,1.4,1.6 c0.4,0.6,0.6,1.3,0.6,1.9v8.7c0,1.5-0.5,2.9-1.7,4.1c-1.1,1.1-2.4,1.7-4.1,1.7H23.7c-1.6,0-3-0.5-4.1-1.7c-1.1-1.1-1.7-2.4-1.7-4.1 v-8.7c0-0.5,0.2-1.2,0.6-1.8c0.4-0.6,0.9-1.2,1.4-1.6c0.5-0.5,1.1-0.8,1.8-1.3c0.6-0.5,1.1-0.7,1.4-0.8c0.3-0.1,0.5-0.2,0.6-0.3 L49,78c-3.6-3-6.3-6.9-8.2-12.1c-1.8-5-2.8-9.5-2.8-13.5V38c0-3.2,1.3-6.3,3.8-9.5s5.8-5.8,9.9-7.7C55.8,18.8,59.9,17.8,64.1,17.8z  M54.6,25.9c-3.2,1.5-5.8,3.5-7.7,5.8s-3,4.4-3,6.4v14.4c0,3.3,0.8,7.1,2.3,11.3s3.7,7.5,6.5,9.7c1.7,1.4,2.4,3.1,2.2,5.2 c-0.3,2.2-1.3,3.7-3.3,4.6L26.1,95.1c-1.6,0.7-2.4,1.8-2.4,3.3v5.8l80.6-0.1v-5.6c0-0.5,0-0.8-0.1-1.2c-0.1-0.4-0.2-0.6-0.4-0.9 c-0.2-0.3-0.3-0.4-0.4-0.6c-0.2-0.2-0.4-0.3-0.5-0.4c-0.2-0.1-0.4-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.2L77,83.1 c-1.9-0.9-3.1-2.4-3.3-4.6c-0.1-0.6,0-1.3,0.2-2c0.2-0.6,0.5-1.3,0.8-1.8c0.4-0.5,0.8-1.1,1.3-1.5c2.8-2.2,4.9-5.3,6.4-9.5 c1.4-4.2,2.2-8,2.2-11.4V38c0-1.5-0.6-3.1-1.8-4.9c-1.2-1.7-2.7-3.2-4.6-4.7c-1.9-1.4-4.1-2.6-6.6-3.5s-4.9-1.4-7.3-1.4 C60.9,23.6,57.8,24.4,54.6,25.9z"
      />
    </svg>
  )
}

export default Users
