import { useEffect, useRef, useState } from 'react'
import { withConfirmation } from 'simple-core-ui'
import { useBlocker } from '../hooks'

const useUnsavedChanges = (shouldPrompt, callback, options = {}, forceCallback = false) => {
  const retryFn = useRef(() => {})
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)

  useEffect(() => {
    if (confirmedNavigation) {
      retryFn.current()
    }
  }, [confirmedNavigation])

  const handleBlockNavigation = ({ retry }) => {
    const shouldDisplayPrompt = typeof shouldPrompt === 'boolean' ? shouldPrompt : shouldPrompt()
    if (shouldDisplayPrompt) {
      withConfirmation(
        (...args) => {
          if (forceCallback) {
            callback(args)
          } else {
            const [action] = args
            if (action === 'secondaryConfirm') {
              setConfirmedNavigation(true)
              retryFn.current = retry
            } else {
              callback(() => {
                setConfirmedNavigation(true)
                retryFn.current = retry
              })
            }
          }
        },
        {
          title: 'You have unsaved edits',
          text: 'Do you want to save them before leaving?',
          buttons: {
            discard: {
              text: "Don't Save",
              value: 'secondaryConfirm',
              className: 'swal-button--cancel'
            },
            cancel: 'Cancel',
            confirm: {
              text: 'Save',
              value: true
            }
          },
          ...options
        }
      )()
    } else {
      retry()
    }
  }

  useBlocker(handleBlockNavigation, !confirmedNavigation && shouldPrompt)
}

export default useUnsavedChanges
