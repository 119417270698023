import includes from 'lodash/includes'
import omit from 'lodash/omit'
import curry from 'lodash/curry'
import get from 'lodash/get'
import orderBy from 'lodash/orderBy'
import range from 'lodash/range'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

const sortRowsButKeepChildrenNested = (arr, sort, direction) => {
  let parentArray = []
  let childrenArray = []

  // Split the original array into parentArray and childrenArray
  arr.forEach(row => {
    if (row.parent === null) {
      parentArray.push(row)
    } else {
      childrenArray.push(row)
    }
  })
  // Sort the parentArray based on the desired property
  parentArray = orderBy(parentArray, sort, [direction === 'desc' ? 'desc' : 'asc'])

  // Loop through the parentArray and insert the corresponding children next to their parent
  for (let i = 0; i < parentArray.length; i++) {
    const parent = parentArray[i]
    for (let j = 0; j < childrenArray.length; j++) {
      const child = childrenArray[j]
      if (child.parent?.id === parent.id) {
        parentArray.splice(i + 1, 0, child)
        i++
        childrenArray.splice(j, 1)
        j--
      }
    }
  }

  return [...parentArray, ...childrenArray]
}

class DataTableService {
  rowsByCategory
  columns
  sort
  filter
  remotePagination
  totalRows
  multiSort

  constructor(
    rowsByCategory,
    columns,
    sort,
    filter,
    remotePagination,
    totalRows = 0,
    multiSort,
    hasNestedRows,
    filteredTotal
  ) {
    this.rowsByCategory = rowsByCategory
    this.columns = columns
    this.sort = sort || this.getCellContentByColumnKey
    this.filter = filter || this.filterBySearch
    this.totalRows = totalRows
    this.totalFilteredRows = filteredTotal
    this.remotePagination = remotePagination
    this.multiSort = multiSort
    this.hasNestedRows = hasNestedRows
  }

  static getRowsByCategory = (rowsByCategory, category) => {
    if (!rowsByCategory.hasOwnProperty(category)) {
      console.error(
        `Invalid category received: ${category}. Be sure you are providing DataTableContainer with an initialValues prop.`
      )
    }

    return rowsByCategory[category]
  }

  totalEntries = rows => (this.remotePagination ? this.totalRows : rows.length)

  filterableColumnKeys = () =>
    new Set(
      this.columns.filter(({ isFilterable }) => isFilterable).map(({ columnKey }) => columnKey)
    )

  filterBySearch = (rows, search) => {
    return rows.filter(({ cells }) => {
      return cells.some(({ columnKey, content }) => {
        if (this.filterableColumnKeys().has(columnKey)) {
          if (typeof content === 'string') {
            return includes(content.toLowerCase(), search.toLowerCase())
          } else if (content && typeof content === 'object') {
            const column = this.columns.find(col => col.columnKey === columnKey)
            if (Array.isArray(content)) {
              return content.some(item =>
                includes(item[column.filterableBy].toLowerCase(), search.toLowerCase())
              )
            }
            return includes(content[column.filterableBy].toLowerCase(), search.toLowerCase())
          }
        } else {
          return false
        }
      })
    })
  }

  static reorderColumns = (columns, ordering) =>
    columns.map(column => {
      if (column.columnKey === ordering.columnKey) {
        return { ...column, isDesc: ordering.isDesc }
      } else {
        return omit(column, 'isDesc')
      }
    })

  sortableColumnKeys = () =>
    new Set(this.columns.filter(({ isSortable }) => isSortable).map(({ columnKey }) => columnKey))

  getCellContentByColumnKey = (columnKey, row) =>
    get(
      row.cells.find(cell => cell.columnKey === columnKey),
      'content',
      ''
    )

  sortByColumnKey = (rows, columnKey, isDesc) => {
    if (!this.sortableColumnKeys().has(columnKey)) {
      console.error(`Attempting to sort on unsortable columnKey: ${columnKey}.`)
    }

    const getSortByContent = curry(this.sort)(columnKey)

    if (this.multiSort) {
      if (typeof this.multiSort === 'object') {
        const getSortBySecondaryCol = curry(this.sort)(this.multiSort.columnKey)
        return orderBy(
          rows,
          [getSortByContent, getSortBySecondaryCol],
          [isDesc ? 'desc' : 'asc', this.multiSort.isDesc ? 'desc' : 'asc']
        )
      }
      const orderedRows = orderBy(rows, [getSortByContent, 'id'], [isDesc ? 'desc' : 'asc', 'desc'])
      return this.hasNestedRows
        ? sortRowsButKeepChildrenNested(orderedRows, getSortByContent, isDesc ? 'desc' : 'asc')
        : orderedRows
    }

    return this.hasNestedRows
      ? sortRowsButKeepChildrenNested(rows, getSortByContent, isDesc ? 'desc' : 'asc')
      : orderBy(rows, getSortByContent, [isDesc ? 'desc' : 'asc'])
  }

  static getPageBoundaries = (pageSize, filteredTotal) => ({
    firstPage: 0,
    lastPage: filteredTotal === 0 ? 1 : Math.ceil(filteredTotal / pageSize)
  })

  getCorrectedPage = (page, pageSize, totalEntries) => {
    if (pageSize === Infinity) return page

    const { firstPage, lastPage } = DataTableService.getPageBoundaries(pageSize, totalEntries)
    if (page < firstPage) return 1
    if (page > lastPage) return lastPage
    return page
  }

  paginate = (rows, page, pageSize, totalEntries) => {
    const { firstPage, lastPage } = DataTableService.getPageBoundaries(pageSize, totalEntries)

    if (page < firstPage || page > lastPage) {
      console.error(`The page number: ${page} is out of range.`)
    }

    const [start, end] = [pageSize * (page - 1), pageSize * page]

    return rows.slice(start, end)
  }

  static getSurroundingPages = (page, pageSize, filteredTotal) => {
    const { firstPage, lastPage } = DataTableService.getPageBoundaries(pageSize, filteredTotal)

    return {
      previousPage: page - 1 > firstPage ? page - 1 : null,
      nextPage: page + 1 <= lastPage ? page + 1 : null
    }
  }

  static getPageWindow = (windowSize = 5, pageSize, filteredTotal, curPage) => {
    let pageWindow = [curPage]
    // set firstPage to 1 and ignore the zero returned by getPageBoundaries
    // curPage is 1 indexed and getPageBoundaries is 0 indexed leading to this hack
    const firstPage = 1
    const { lastPage } = DataTableService.getPageBoundaries(pageSize, filteredTotal)
    const pages = range(firstPage, lastPage + 1) // Need +1 to include lastPage in range

    const numLeadingPages = Math.ceil(windowSize / 2)
    if (curPage <= numLeadingPages) {
      pageWindow = pages.slice(0, windowSize)
    } else if (curPage > lastPage - numLeadingPages) {
      if (windowSize < lastPage) {
        pageWindow = pages.slice(lastPage - windowSize, lastPage)
      } else {
        pageWindow = pages
      }
    } else {
      pageWindow = pages.slice(curPage - numLeadingPages, curPage - numLeadingPages + windowSize)
    }

    return pageWindow
  }

  static getCategoryCounts = rowsByCategory =>
    Object.keys(rowsByCategory).reduce((categoryCounts, category) => {
      categoryCounts[category] = rowsByCategory[category].length
      return categoryCounts
    }, {})

  getUpdatedResults = ({ pageSize, ordering, search, page, category }) => {
    // Filter rows by category
    const categoryRows = DataTableService.getRowsByCategory(this.rowsByCategory, category)

    if (this.remotePagination) {
      // Calculate the next/previous pages
      const { previousPage, nextPage } = DataTableService.getSurroundingPages(
        page,
        pageSize,
        this.totalFilteredRows || this.totalRows
      )

      return {
        rows: categoryRows,
        columns: DataTableService.reorderColumns(this.columns, ordering),
        page,
        previousPage,
        nextPage,
        totalEntries: this.totalRows,
        filteredTotal: this.totalFilteredRows || this.totalRows,
        categoryCounts: DataTableService.getCategoryCounts(this.rowsByCategory)
      }
    }

    // Calculate total entries on entire unfiltered dataset
    const totalEntries = this.totalEntries(categoryRows)
    // Massage rows by filtering/sorting/paginating
    const filteredRows = this.filter(categoryRows, search)
    // Update page parameter (in case of item(s) deletion 'page' might not be correct)
    const correctedPage = this.getCorrectedPage(page, pageSize, filteredRows.length)

    // Calculate total entries on entire filtered dataset (prior to pagination)
    const filteredTotal = this.totalEntries(filteredRows)

    const filteredSortedRows = this.sortByColumnKey(
      filteredRows,
      ordering.columnKey,
      ordering.isDesc
    )
    const filteredSortedPaginatedRows = this.paginate(
      filteredSortedRows,
      correctedPage,
      pageSize,
      filteredTotal
    )

    // Calculate the next/previous pages
    const { previousPage, nextPage } = DataTableService.getSurroundingPages(
      correctedPage,
      pageSize,
      filteredTotal
    )

    return {
      rows: filteredSortedPaginatedRows,
      columns: DataTableService.reorderColumns(this.columns, ordering),
      page: correctedPage,
      previousPage,
      nextPage,
      totalEntries,
      filteredTotal,
      categoryCounts: DataTableService.getCategoryCounts(this.rowsByCategory)
    }
  }
}

registration.register({
  name: 'DataTableService',
  description:
    'This service can be used in conjunction with the DataTableContainer in order to support full filtering/sorting/pagination functionality purely on the client-side.',
  props: [
    {
      name: 'rowsByCategory',
      type: '{[CategoryValue]: Array<Rows>} (look at example below for sample)',
      note: 'The rows for the entire data set grouped by category.'
    },
    {
      name: 'columns',
      type: 'Array<Column> (look at example below for sample)',
      note: 'The same columns that you would provide the Table/DataTableContainer components.'
    },
    {
      name: 'sort',
      optional: true,
      type: 'function -- (ColumnKey, Row) => string | number',
      note:
        'This is an optional argument to customize sorting (in cases where the content of a sortable cell is not just a string or number) that, provided a ColumnKey and a Row, should return a value to order by.'
    },
    {
      name: 'filter',
      optional: true,
      type: 'function -- (Rows, Search) => Rows',
      note:
        'This is an optional argument to customize filtering (in cases where the content of a filterable cell is not just a string) that, provided the Rows and the Search string, should return filtered down Rows.'
    },
    {
      name: 'DataTableService.getUpdatedResults()',
      type: 'function -- (UpdateParams) => UpdateResults (look at example below for sample)',
      note:
        'Where the above arguments should be provided to the constructor to instantiate the DataTableService, the main method that should be used to generate the proper results is getUpdatedResults(). Provided the UpdateParams that is obtained via the updateTable callback prop of the DataTableContainer (which is invoked any time the DataTableContainer is sorted/filtered/paginated), the service can ingest the param information and generate the proper response.'
    }
  ],
  example: {
    literal: `
const columns = [
  {
    columnKey: 'name',
    content: 'Name',
    isSortable: true,
    isFilterable: true,
    isDesc: true
  },
  {
    columnKey: 'roles',
    content: 'Roles'
  },
  {
    columnKey: 'permissions',
    content: 'Permissions'
  }
]


export const rowsByCategory = {
  matter: [
    {
      id: 0,
      cells: [{
        columnKey: 'name',
        content: 'Alperin v. Vatican Bank'
      }, {
        columnKey: 'roles',
        content: ['Captain', 'Resident', 'Primary Contact', 'Manager', 'Outside Counsel']
      }, {
        columnKey: 'permissions',
        content: ['Grant']
      }]
    },
    // ...rest of matter rows
  ],
  vendor: [
    {
      id: 0,
      cells: [{
        columnKey: 'name',
        content: 'Kirkland & Ellis'
      }, {
        columnKey: 'roles',
        content: ['Resident', 'Engineer', 'Outside Counsel']
      }, {
        columnKey: 'permissions',
        content: ['Grant']
      }]
    },
    // ...rest of vendor rows
  ],
  legalEntity: [
    {
      id: 0,
      cells: [{
        columnKey: 'name',
        content: 'Walmart'
      }, {
        columnKey: 'roles',
        content: ['Business Contact', 'Engineer', 'Primary Contact']
      }, {
        columnKey: 'permissions',
        content: ['Grant']
      }]
    },
    // ...rest of legalEntity rows
  ],
  practiceArea: [
    {
      id: 0,
      cells: [{
        columnKey: 'name',
        content: 'Administrative law'
      }, {
        columnKey: 'roles',
        content: ['Captain', 'Engineer', 'Business Contact', 'Manager', 'Resident']
      }, {
        columnKey: 'permissions',
        content: ['Update']
      }]
    },
    // ...rest of practiceArea rows
  ]
}


// This would have been provided to us from DataTableContainer's
// updateTable prop. The updateTable prop is a callback which will be
// provided this data structure any time an interaction with the table
// occurs, such as filtering, sorting, or paginating.
const updateParams = {
  pageSize: 25,
  ordering: {columnKey: 'name', isDesc: false},
  search: 'A',
  page: 1,
  category: 'matter'
}

// We will instantiate the service, providing it with the entire dataset
//
// Note: We don't pass in the sort callback because 'name' is the only
// sortable column and it's cell contents are string values.
const contactService = new DataTableService(rowsByCategory, columns)

// We can now use the service to get the most up to date filtered dataset.
const results = contactService.getUpdatedResults(updateParams)
// or more explicitly
const results = contactService.getUpdatedResults(
  pageSize: updateParams.pageSize,
  ordering: updateParams.ordering,
  search: updateParams.search,
  page: updateParams.page,
  category: updateParams.category
)


/*

Now results would look something like...
----------------------------------------

{
  rows: [
    {
      id: 0,
      cells: [{
        columnKey: 'name',
        content: 'Alperin v. Vatican Bank'
      }, {
        columnKey: 'roles',
        content: ['Captain', 'Resident', 'Primary Contact', 'Manager', 'Outside Counsel']
      }, {
        columnKey: 'permissions',
        content: ['Grant']
      }]
    },
    // ...rest of filtered/sorted/paginated matter rows
  ],
  columns: [
    {
      columnKey: 'name',
      content: 'Name',
      isSortable: true,
      isFilterable: true,
      isDesc: false
    },
    {
      columnKey: 'roles',
      content: 'Roles'
    },
    {
      columnKey: 'permissions',
      content: 'Permissions'
    }
  ],
  previousPage: null,
  nextPage: 2,
  totalEntries: 150,
  filteredTotal: 50,
  categoryCounts: {
    matter: 200,
    vendor: 23,
    legalEntity: 12,
    practiceArea: 4
  }
}

*/`,
    render: () => {}
  },
  category: CATEGORY.TABLES,
  path: 'services/DataTableService'
})

export default DataTableService
