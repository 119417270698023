/* eslint-disable no-console */
import * as React from 'react'
import classNames from 'classnames'
import { IoIosClose } from 'react-icons/io'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import s from './Tag.scss'

const Tag = ({ isSelected, selectCb, deleteCb, color, children }) => (
  <span
    className={classNames(s.container, {
      [s.select]: selectCb,
      [s.isSelected]: isSelected,
      [s[color]]: color
    })}
    onClick={selectCb}
  >
    <span className={s.text}>{children}</span>
    {deleteCb && <IoIosClose className={s.delete} onClick={deleteCb} />}
  </span>
)

Tag.defaultProps = {
  color: 'black'
}

registration.register({
  name: 'Tag',
  description:
    'A component that can be used as a tag on a given object. An example of its use is to identify roles in Assigned Contacts.',
  props: [
    {
      name: 'isSelected',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that will render the Tag as filled in if true.'
    },
    {
      name: 'selectCb',
      type: 'function -- () => void',
      note: 'The callback function that will be invoked when the Tag is clicked.'
    },
    {
      name: 'deleteCb',
      optional: true,
      type: 'function -- () => void',
      note:
        'If provided a small delete icon will render when hovering over the Tag. The callback function that will be invoked when the delete icon is clicked.'
    },
    {
      name: 'color',
      type: 'string -- (black | red | blue)',
      note: 'Default: black - The color to render the Tag.'
    },
    {
      name: 'children',
      type: 'React.Node',
      note: 'The content to be displayed within the Tag. Will usually just be a string of text.'
    }
  ],
  example: {
    literal: `
<React.Fragment>
  <section style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
    <Tag color='black'>Standard Black</Tag>
    <Tag color='red'>Standard Red</Tag>
    <Tag color='blue'>Standard Blue</Tag>
  </section>
  <section style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
    <Tag color='black' selectCb={() => console.log('You clicked the black tag.')}>Clickable Black</Tag>
    <Tag color='red' selectCb={() => console.log('You clicked the red tag.')}>Clickable Red</Tag>
    <Tag color='blue' selectCb={() => console.log('You clicked the blue tag.')}>Clickable Blue</Tag>
  </section>
  <section style={{display: 'flex', flexFlow: 'row wrap', justifyContent: 'center'}}>
    <Tag color='black' deleteCb={() => console.log('You tried to delete the black tag.')}>Deletable Black</Tag>
    <Tag color='red' deleteCb={() => console.log('You tried to delete the red tag.')}>Deletable Red</Tag>
    <Tag color='blue' deleteCb={() => console.log('You tried to delete the blue tag.')}>Deletable Blue</Tag>
  </section>
</React.Fragment>`.trim(),
    render: () => (
      <React.Fragment>
        <section style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
          <Tag color="black">Standard Black</Tag>
          <Tag color="red">Standard Red</Tag>
          <Tag color="blue">Standard Blue</Tag>
        </section>
        <section style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
          <Tag color="black" selectCb={() => console.log('You clicked the black tag.')}>
            Clickable Black
          </Tag>
          <Tag color="red" selectCb={() => console.log('You clicked the red tag.')}>
            Clickable Red
          </Tag>
          <Tag color="blue" selectCb={() => console.log('You clicked the blue tag.')}>
            Clickable Blue
          </Tag>
        </section>
        <section style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'center' }}>
          <Tag color="black" deleteCb={() => console.log('You tried to delete the black tag.')}>
            Deletable Black
          </Tag>
          <Tag color="red" deleteCb={() => console.log('You tried to delete the red tag.')}>
            Deletable Red
          </Tag>
          <Tag color="blue" deleteCb={() => console.log('You tried to delete the blue tag.')}>
            Deletable Blue
          </Tag>
        </section>
      </React.Fragment>
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Tag/Tag'
})

export default Tag
