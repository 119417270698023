import React, { ReactNode } from 'react'
import Truncate from 'react-truncate'
import s from './Ellipsis.scss'
import cn from 'classnames'

type Props = {
  children?: ReactNode
  lines: number
  className?: string
  width?: number
}

type State = {
  isTruncated: boolean
}

class Ellipsis extends React.Component<Props, State> {
  state = { isTruncated: false }

  static defaultProps = {
    lines: 2
  }

  render() {
    const { children, lines, className, width } = this.props
    const { isTruncated } = this.state
    return (
      <div data-tip={isTruncated ? children : ''} className={cn(s.container, className)}>
        <Truncate
          lines={lines}
          width={width}
          onTruncate={isTruncated =>
            isTruncated !== this.state.isTruncated && this.setState({ isTruncated })
          }
        >
          {children}
        </Truncate>
      </div>
    )
  }
}

export default Ellipsis
