/* eslint-disable no-console */

import {
  useCallback,
  useState,
  forwardRef,
  ChangeEventHandler,
  KeyboardEventHandler,
  CSSProperties,
  InputHTMLAttributes
} from 'react'
import { Spinner, useSyncStateWithProps } from 'simple-core-ui'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import cn from 'classnames'
import s from './TextInput.scss'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

function cleanNum(amount: string) {
  return amount.replace(/[^0-9\.\-]+/g, '')
}

type ComponentAttributes = InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>

type Props = {
  id?: string
  style?: CSSProperties
  value: string | number
  hasSpellcheck?: boolean
  placeholder?: string
  onChange(value: any): void
  onEnter?(value: any): void
  onBlur?: ComponentAttributes['onBlur']
  onFocus?: ComponentAttributes['onFocus']
  onKeyPress?: ComponentAttributes['onKeyPress']
  isDisabled?: boolean
  isLoading?: boolean
  type?: 'text' | 'textarea' | 'number' | 'date'
  testid?: string
  className?: string
  containerClassName?: string
  maxLength?: number
  step?: string
  ariaLabel?: string
  debounceDelay?: number
  pattern?(value: string): string
}

const TextInput = forwardRef<HTMLInputElement & HTMLTextAreaElement, Props>((props, ref) => {
  const {
    id = '',
    style,
    value: initialValue = '',
    hasSpellcheck,
    placeholder,
    onChange,
    onEnter = noop,
    onBlur,
    onFocus,
    onKeyPress,
    isDisabled,
    isLoading,
    type = 'text',
    testid,
    className,
    containerClassName,
    maxLength,
    step,
    ariaLabel,
    debounceDelay = 0,
    pattern
  } = props
  const isTextarea = type === 'textarea'
  const Component = isTextarea ? 'textarea' : 'input'
  const baseContainerClassName = isTextarea ? s.containerTextarea : s.container
  const baseClassName = typeof isLoading === 'boolean' ? s.spinnerInput : s.input

  const [value, setValue] = useSyncStateWithProps(initialValue)

  const sendApiRequest = (value: any) => {
    onChange && onChange(value)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnChange = useCallback(debounce(sendApiRequest, debounceDelay), [onChange])

  const onChangeHandler: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = ({
    target: { value }
  }) => {
    value = pattern ? pattern(value) : type === 'number' ? cleanNum(value) : value
    setValue(value)
    debounceDelay ? debounceOnChange(value) : sendApiRequest(value)
  }

  const onKeyPressHandler: KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
    e: any
  ): void => {
    onKeyPress
      ? onKeyPress(e)
      : e.key.toLowerCase() === 'enter' && onEnter
      ? onEnter(e.target.value)
      : noop
  }

  return (
    <div className={cn(baseContainerClassName, containerClassName)}>
      <Component
        id={id}
        aria-label={ariaLabel}
        data-testid={testid}
        className={cn(baseClassName, className)}
        style={style}
        type={type}
        value={debounceDelay ? value : initialValue}
        placeholder={placeholder}
        disabled={isDisabled || false}
        spellCheck={hasSpellcheck || isTextarea}
        onChange={onChangeHandler}
        onBlur={onBlur}
        onKeyPress={onKeyPressHandler}
        onFocus={onFocus}
        ref={ref}
        maxLength={maxLength}
        step={step}
      />
      {isLoading && <Spinner />}
    </div>
  )
})

registration.register({
  name: 'TextInput',
  description:
    'The controlled core component for all input-based components that need to be rendered. (Look at type prop for supported input types)',
  props: [
    {
      name: 'value',
      type: 'string',
      note: 'The value of the input.',
      defaultValue: ''
    },
    {
      name: 'onChange',
      type: 'function --- (string) => void',
      note:
        'The callback which will be invoked when a change has occurred on the input value. The update value is provided as an argument to the callback.'
    },
    {
      name: 'onEnter',
      optional: true,
      type: '() => void',
      note: 'The callback which will be invoked when the enter key is pressed by the user.'
    },
    {
      name: 'placeholder',
      optional: true,
      type: 'string',
      note: 'The placeholder text to be displayed in the input.'
    },
    {
      name: 'debounceDelay',
      optional: true,
      type: 'number',
      defaultValue: '0',
      note: 'The number of milliseconds that the input should be delayed when a change occurs.'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      note: 'Default: false - If true it will set the input as disabled.'
    },
    {
      name: 'hasSpellcheck',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - If true it will add spellchecking to the input. Only useful for type textarea.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note:
        'Default: false - If true it will set the loading state of the input and show the spinner to the right of the text.'
    },
    {
      name: 'onBlur',
      optional: true,
      type: 'function -- (string) => void',
      note:
        'A callback that will be invoked when the input is blurred. It will recieve the value of the input as an argument.'
    },
    {
      name: 'containerClassName',
      optional: true,
      type: 'string',
      note:
        'Will add custom styling to override the container of the input component. Used primarily for sizing the input.'
    },
    {
      name: 'className',
      optional: true,
      type: 'Object',
      note: 'Will add custom styling overrides to the input component.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      note: 'Will add custom styling overrides to the input component.'
    },
    {
      name: 'type',
      optional: true,
      type: 'string -- ("text" | "textarea" | "number")',
      defaultValue: 'text',
      note: 'The type of input that will be rendered.'
    }
  ],
  example: {
    literal: `
<section
  style={{
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    justifyContent: 'center'
  }}
>
  <TextInput
    placeholder='Text Input'
    onChange={value => { console.log('Value of Text Input is now: ' + value) }}
    style={{ margin: '1em auto', maxWidth: '300px' }}
  />
  <TextInput
    placeholder='Text Input (Disabled)'
    onChange={value => {}} isDisabled
    style={{ margin: '1em auto', maxWidth: '300px' }}
  />
  <TextInput
    placeholder='Number Input'
    onChange={value => { console.log('Value of Number Input is now: ' + value) }}
    type='number'
    style={{ margin: '1em auto', maxWidth: '300px' }}
  />
  <TextInput
    placeholder='TextArea Input'
    onChange={value => { console.log('Value of TextArea is now: ' + value) }}
    type='textarea'
    style={{ margin: '1em auto', maxWidth: '300px', width: '100%' }}
  />
</section>`.trim(),
    render: () => {
      const ExampleForm = () => {
        const [text, setText] = useState('')
        const [number, setNumber] = useState('')
        const [longText, setLongText] = useState('')

        return (
          <section
            style={{
              display: 'flex',
              flexFlow: 'column nowrap',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <TextInput
              placeholder="Text Input"
              onChange={value => {
                setText(value)
                console.log(`Value of Text Input is now: ${value}`)
              }} // eslint-disable-line
              value={text}
              style={{ margin: '1em auto', maxWidth: '300px' }}
            />
            <TextInput
              placeholder="Text Input (Disabled)"
              onChange={value => {}}
              value=""
              isDisabled
              style={{ margin: '1em auto', maxWidth: '300px' }}
            />
            <TextInput
              placeholder="Number Input"
              onChange={value => {
                setNumber(value)
                console.log(`Value of Number Input is now: ${value}`)
              }} // eslint-disable-line
              value={number}
              type="number"
              style={{ margin: '1em auto', maxWidth: '300px' }}
            />
            <TextInput
              placeholder="TextArea Input"
              onChange={value => {
                setLongText(value)
                console.log(`Value of TextArea is now: ${value}`)
              }} // eslint-disable-line
              value={longText}
              type="textarea"
              style={{ margin: '1em auto', maxWidth: '300px', width: '100%' }}
            />
          </section>
        )
      }
      return ExampleForm()
    }
  },
  category: CATEGORY.FORM,
  path: 'components/Core/TextInput/TextInput'
})

export default TextInput
