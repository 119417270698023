import { CSSProperties, Component, Fragment } from 'react'
import classNames from 'classnames'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'

import { IoIosArrowDown } from 'react-icons/io'
import { Button, If, OutsideClickContainer } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

import s from './ButtonDropdown.scss'
import ReactTooltip from 'react-tooltip'

interface Option {
  label: string
  value: unknown
  isDisabled?: boolean
  hasTooltip?: boolean
  tooltipText?: string
}
interface Props {
  value?: unknown
  options: Array<Option>
  displayText?: string
  style?: CSSProperties
  className?: string
  alignRight?: boolean
  isLoading?: boolean
  theme?: string
  isSecondary?: boolean
  isPrimary?: boolean
  isDisabled?: boolean
  listStyles?: CSSProperties
  listItemStyles?: CSSProperties
  onSelect(option: Option): void
  hasNewDesign?: boolean
}

type State = {
  isOpen: boolean
}

const TOOLTIP_CUSTOM_TEXT = 'Contact customer success to upgrade.'

class ButtonDropdown extends Component<Props, State> {
  state = {
    isOpen: false
  }

  static defaultProps = {
    style: {},
    className: '',
    theme: 'eb',
    isSecondary: false,
    isPrimary: false,
    isDisabled: false
  }

  openDropdown = () => this.setState({ isOpen: true })

  closeDropdown = () => this.setState({ isOpen: false })

  onSelect = (option: Option) => {
    this.props.onSelect(option)
    this.closeDropdown()
  }

  label = () => {
    const { value, options, displayText } = this.props

    return (
      displayText ||
      get(
        options.find(option => isEqual(option.value, value)),
        'label'
      )
    )
  }

  render() {
    const { isOpen } = this.state
    const {
      value,
      options,
      style,
      className,
      alignRight,
      isLoading,
      isSecondary,
      isPrimary,
      isDisabled,
      listStyles,
      listItemStyles,
      hasNewDesign
    } = this.props

    return (
      <OutsideClickContainer
        closeComponent={this.closeDropdown}
        styles={{ display: 'inline-flex' }}
      >
        <section className={classNames(s.container, className)}>
          <Button
            onClick={isOpen ? this.closeDropdown : this.openDropdown}
            style={{ margin: '0', ...style }}
            isLoading={isLoading}
            isSecondary={isSecondary}
            isPrimary={isPrimary}
            isDisabled={isDisabled}
            hasNewDesign={hasNewDesign}
          >
            {this.label()}
            <If condition={!isLoading}>
              <IoIosArrowDown className={s.icon} />
            </If>
          </Button>
          <If condition={isOpen && !isLoading}>
            <ul
              className={classNames(s.options, { [s.alignRight]: alignRight })}
              style={{ ...listStyles }}
            >
              {options.map((option, idx) => (
                <Fragment key={idx}>
                  <li
                    className={classNames(s.option, {
                      [s.selected]: isEqual(option.value, value),
                      [s.disabled]: option.isDisabled
                    })}
                    onClick={() => {
                      if (!option.isDisabled) this.onSelect(option)
                    }}
                    style={{ ...listItemStyles }}
                    data-for={`upgradeTier-${option.label}`}
                    data-tip
                  >
                    {option.label}
                  </li>
                  {option.hasTooltip && (
                    <ReactTooltip
                      id={`upgradeTier-${option.label}`}
                      type="light"
                      effect="solid"
                      place="top"
                      border
                    >
                      {option.tooltipText || TOOLTIP_CUSTOM_TEXT}
                    </ReactTooltip>
                  )}
                </Fragment>
              ))}
            </ul>
          </If>
        </section>
      </OutsideClickContainer>
    )
  }
}

registration.register({
  name: 'ButtonDropdown',
  description:
    'A dropdown that will render as a button. The dropdown will show up when the button is clicked.',
  props: [
    {
      name: 'value',
      optional: true,
      type: 'Option.value (any)',
      note: "The selected options's value."
    },
    {
      name: 'options',
      type: 'Array<{label: string, value: any}>',
      note: 'The options that will be availible in the dropdown.'
    },
    {
      name: 'onSelect',
      type: '({label: string, value: any}: Option) => void',
      note: 'The callback to get invoked when a dropdown item is selected.'
    },
    {
      name: 'displayText',
      optional: true,
      type: 'string',
      note:
        'If provided this will be used as the text shown in the button itself. If not provided the value prop is used to derived the selected options label, which is then used.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      note: 'Custom styles for the dropdown button.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      note: 'Custom className for dropdown container.'
    },
    {
      name: 'isLoading',
      optional: true,
      type: 'boolean',
      note: 'Will render loading state of button.'
    },
    {
      name: 'isDisabled',
      optional: true,
      type: 'boolean',
      note: 'Will disable the button which triggers the dropdown.'
    },
    {
      name: 'alignRight',
      optional: true,
      type: 'boolean',
      note:
        'Will align the dropdown menu along the right-side. This is useful if button is placed along the right side (i.e in a panel header).'
    },
    {
      name: 'theme',
      optional: true,
      type: 'eb | cg',
      note: 'The theme to render the component in.'
    }
  ],
  example: {
    literal: "Reference the component's file for example code.",
    render: () => {
      const ASYNC_ACTIONS = ['MARK_AS_PAID', 'RESUME_REVIEW', 'RESUME_PAYMENT']

      const OPTIONS = [
        { label: 'Mark as Paid (Async)', value: 'MARK_AS_PAID' },
        { label: 'Mark as Sent to AP', value: 'MARK_AS_SENT_TO_AP' },
        { label: 'Pause Review', value: 'PAUSE_REVIEW' },
        { label: 'Resume Review (Async)', value: 'RESUME_REVIEW' },
        { label: 'Pause Payment', value: 'PAUSE_PAYMENT' },
        { label: 'Resume Payment (Async)', value: 'RESUME_PAYMENT' }
      ]

      class Example extends Component {
        state = { selected: 'MARK_AS_PAID', isLoading: false }

        updatedSelected = ({ value }: { label: string; value: string }) => {
          if (ASYNC_ACTIONS.includes(value)) {
            this.setState({ isLoading: true })
            setTimeout(() => this.setState({ selected: value, isLoading: false }), 3000)
          } else {
            this.setState({ selected: value })
          }
        }

        render() {
          return (
            <section
              style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                justifyContent: 'space-around'
              }}
            >
              <ButtonDropdown
                value={this.state.selected}
                onSelect={this.updatedSelected}
                options={OPTIONS}
                isLoading={this.state.isLoading}
              />
              <ButtonDropdown
                displayText="Actions"
                onSelect={this.updatedSelected}
                options={OPTIONS}
                isLoading={this.state.isLoading}
              />
              <ButtonDropdown
                displayText="Actions (Right)"
                onSelect={this.updatedSelected}
                options={OPTIONS}
                isLoading={this.state.isLoading}
                alignRight
              />
              <ButtonDropdown
                displayText="Actions (Disabled)"
                onSelect={this.updatedSelected}
                options={OPTIONS}
                isLoading={this.state.isLoading}
                isDisabled
              />
            </section>
          )
        }
      }

      return <Example />
    }
  },
  category: CATEGORY.FORM,
  path: 'components/Core/ButtonDropdown/ButtonDropdown'
})

export default ButtonDropdown
