import classNames from 'classnames'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'

import s from './Spinner.scss'

const Spinner = ({ style = {}, className = '' }) => (
  <span className={classNames(s.spinner, className)} style={style} />
)

registration.register({
  name: 'Spinner',
  description: 'A simple spinner with a linear animation timing.',
  props: [
    { name: 'style', type: 'object', optional: true, note: 'Custom styles to be applied.' },
    { name: 'className', type: 'string', optional: true, note: 'Custom class to be applied.' }
  ],
  example: {
    literal:
      "<Spinner style={{display: 'block', width: '100%', height: '2em', margin: 0, fontSize: '1.5em'}} />",
    render: () => (
      <Spinner
        style={{
          display: 'block',
          width: '100%',
          height: '2em',
          margin: 0,
          fontSize: '1.5em'
        }}
      />
    )
  },
  category: CATEGORY.ANIMATE,
  path: 'components/Core/Spinner/Spinner'
})

export default Spinner
