import { useMemo, useEffect, useState } from 'react'
import DataTableContainer from './DataTableContainer'
import { DataTableSerializer } from 'simple-core-ui/serializers'
import { DataTableService } from 'simple-core-ui/services'
import { useLoading } from 'simple-core-ui/hooks'
import ReactTooltip from 'react-tooltip'
import s from 'components/Core/DataTable/DataTable.scss'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import { makeGetRequest } from 'simple-core-ui/utils/api'

interface Row {
  id: number
  [x: string]: unknown
}

interface NormalizedRow {
  id: string
  cells: Column[]
}

interface Column {
  columnKey: string
  content: any
  isSortable?: boolean
  isFilterable?: boolean
  filteredBy?: string
  isDesc?: boolean
  style?: {
    [x: string]: any
  }
}

interface Category {
  label: string | JSX.Element
  value: string
  count?: number
  isTile?: boolean
}

interface Results {
  rows: Row[]
  columns: Column[]
  nextPage: number
  page: number
  previousPage: number | null
  totalEntries: number
  filteredTotal: number
}

export interface Params {
  pageSize: number
  ordering: { columnKey: string; isDesc: boolean }
  search: string
  page: number
  category: string
}

interface Props {
  columns: Array<Column>
  params: Params
  categories: Array<Category>
  categoryKey?: string
  remotePagination?: boolean
  getNormalizedCellContent?: (columnKey: string, row: NormalizedRow) => number | string
  totalEntries?: number
  filteredTotal?: number
  hasTooltip?: boolean
  selectableRow?: boolean
  selectAll?: boolean
  selectAllRowsCb?: ({ selectedRows }: { selectedRows: number[] }) => void
  selectRowCb?: ({ selectedRows }: { selectedRows: number[] }) => void
  selectAllRows?: () => void
  selectRow?: ({ id }: Row) => void
  selectedRows?: Set<number>
  allRowsSelected?: boolean
  hasNestedRows?: boolean
  multiSort?:
    | boolean
    | {
        columnKey: string
        isDesc: boolean
      }
  // All other props
  [x: string]: unknown
}

interface WithUrl extends Props {
  url: string | (() => string)
  rows?: never
  isLoading?: never
  fetchCb?: (result: any) => void
  serializer: (result: any) => Array<Row>
}
interface WithRows extends Props {
  url?: never
  rows: Array<Row>
  isLoading?: boolean
  fetchCb?: never
  serializer?: never
}

const _getGroups = (rows: Row[], categories: Category[], key: keyof Row) => {
  return categories.reduce((acc, cat) => {
    const { value } = cat
    acc[value] =
      !Array.isArray(value) && value === 'all'
        ? rows
        : rows.filter(row => value.includes(row[key] as string))
    return acc
  }, {} as Record<string, Row[]>)
}

const groupByCategory = (rows: Row[], categories: Category[], key?: keyof Row) => {
  const result = { all: rows }
  if (categories.length > 0 && key) {
    const groups = _getGroups(rows, categories, key)
    return {
      ...groups,
      all: rows
    }
  }
  return result
}

const DataTableWrapper = ({
  rows,
  columns,
  params,
  categories,
  categoryKey,
  remotePagination,
  getNormalizedCellContent,
  totalEntries,
  filteredTotal,
  hasTooltip,
  selectableRow,
  selectAll,
  selectAllRowsCb,
  selectRowCb,
  selectAllRows,
  selectRow,
  selectedRows,
  allRowsSelected,
  tooltipClassName,
  multiSort = false,
  hasNestedRows = false,
  fetchCb,
  serializer,
  url,
  hasInfinityScroll,
  ...props
}: WithRows | WithUrl) => {
  const [selectedRowsLocal, setSelectedRowsLocal] = useState<number[]>([])
  const [allRowsSelectedLocal, setAllRowsSelectedLocal] = useState(false)
  const [items, setItems] = useState<Row[]>([])
  const [localIsLoading, withLoadingLocks] = useLoading()
  const [localTotalEntries, setLocalTotalEntries] = useState(0)

  if (!url && rows && !isEqual(rows, items)) {
    setItems(rows)
  }

  const fetchItems = async () => {
    if (url) {
      const response = await withLoadingLocks(makeGetRequest(url))
      const serialized = serializer ? serializer(response.rows) : response.rows
      fetchCb?.({ rows: serialized, totalEntries: response.totalEntries })

      if (!rows) {
        setItems(hasInfinityScroll && params.page > 1 ? [...items, ...serialized] : serialized)
        setLocalTotalEntries(response.totalEntries)
      }
    }
  }

  useEffect(() => {
    if (url) {
      fetchItems()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url])

  const results: Results = useMemo(() => {
    if (!remotePagination) {
      const rowsByCategory = groupByCategory(items, categories, categoryKey)
      const wrapperService = new DataTableService(
        DataTableSerializer.getRowsByCategory(rowsByCategory, columns),
        columns,
        getNormalizedCellContent,
        null,
        null,
        undefined,
        multiSort,
        hasNestedRows
      )

      ReactTooltip.rebuild()
      return wrapperService.getUpdatedResults(params)
    }
    const rowsByCategory = { [params.category]: items }

    const wrapperService = new DataTableService(
      DataTableSerializer.getRowsByCategory(rowsByCategory, columns, props.customBlankValue),
      columns,
      null,
      null,
      remotePagination,
      totalEntries ?? localTotalEntries,
      false,
      false,
      filteredTotal
    )

    return wrapperService.getUpdatedResults(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, params, columns])

  const selectRowLocal = ({ id }: Row) => {
    if (selectedRowsLocal.includes(id)) {
      const index = selectedRowsLocal.findIndex(r => r === id)
      const arr = [...selectedRowsLocal]
      arr.splice(index, 1)
      setSelectedRowsLocal(arr)
    } else {
      setSelectedRowsLocal([...selectedRowsLocal, id])
    }

    selectRowCb && selectRowCb({ selectedRows: selectedRowsLocal })
  }

  const selectAllRowsLocal = () => {
    setAllRowsSelectedLocal(allRowsSelectedLocal => !allRowsSelectedLocal)
    if (allRowsSelectedLocal) {
      setSelectedRowsLocal([])
    } else {
      setSelectedRowsLocal(results.rows.map(i => i.id))
    }

    selectAllRowsCb && selectAllRowsCb({ selectedRows: selectedRowsLocal })
  }

  useEffect(() => {
    if (hasTooltip) {
      ReactTooltip.rebuild()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, params, columns])

  const showBulkSelectAll = items.some(row => row.canEdit !== false)

  return (
    <div>
      <DataTableContainer
        initialValues={{ ...params, page: results.page }}
        rows={results.rows}
        columns={results.columns}
        categories={categories}
        previousPage={results.previousPage}
        nextPage={results.nextPage}
        totalEntries={results.totalEntries}
        filteredTotal={results.filteredTotal}
        showBulkSelectAll={showBulkSelectAll}
        selectAllRows={selectAllRows || (selectAll && selectAllRowsLocal)}
        selectRow={selectRow || (selectableRow && selectRowLocal)}
        selectedRows={new Set(selectedRows || selectedRowsLocal)}
        allRowsSelected={allRowsSelected || allRowsSelectedLocal}
        theme={props.theme || 'eb'}
        isLoading={props.isLoading || localIsLoading}
        hasInfinityScroll={hasInfinityScroll}
        {...props}
      />
      {hasTooltip ? (
        <ReactTooltip
          effect="solid"
          type="light"
          className={cn(s.tooltipPopup, tooltipClassName)}
        />
      ) : null}
    </div>
  )
}

export default DataTableWrapper
