import classNames from 'classnames'

import TableHeadSortIcons from './TableHeadSortIcons'
import s from './TableHead.scss'

const TableHeadCell = ({ content, sortColumn, style, isSortable, isDesc }) => (
  <th
    onClick={sortColumn}
    style={style}
    className={classNames(s.cell, { [s.activeHeader]: typeof isDesc === 'boolean' })}
  >
    {isSortable && <TableHeadSortIcons isDesc={isDesc} />}
    {content}
  </th>
)

TableHeadCell.defaultProps = {
  sortColumn: () => {}
}

export default TableHeadCell
