import classNames from 'classnames'

import { If } from 'simple-core-ui'

import registration from 'docs/registration'
import { CATEGORY } from 'docs/constants'
import Flex from '../../../docs/Flex/Flex'

import s from './Alert.scss'

const Alert = ({ message, items = [], status = 'default', style = {}, className = '' }) => (
  <section className={classNames(s.container, s[status], { [className]: className })} style={style}>
    <section className={s.message}>
      {message}
      {items.length ? ':' : ''}
    </section>
    <If condition={items.length}>
      {() => (
        <ul className={s.items}>
          {items.map((item, idx) => (
            <li className={s.item} key={idx}>
              {item}
            </li>
          ))}
        </ul>
      )}
    </If>
  </section>
)

registration.register({
  name: 'Alert',
  description:
    'A simple container that can display a message to the user. It can optionally list of items to support the message.',
  props: [
    {
      name: 'message',
      type: 'React.Node',
      note: 'The main message to be displayed.'
    },
    {
      name: 'items',
      type: 'Array<React.Node>',
      optional: true,
      note: 'An optional prop that will render list items below the main message.'
    },
    {
      name: 'status',
      optional: true,
      type: 'success | error | warning | info | default',
      defaultValue: 'default',
      note: 'This will change the colors used to render the message.'
    },
    {
      name: 'style',
      optional: true,
      type: 'Object',
      note: 'Can be set to add custom styling to the element inline.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      note: 'Can be set to add custom styling to the element.'
    }
  ],
  example: {
    literal: `
<Alert
  message="The following fields were successfully updated"
  items={['Budget Final Amount', 'Budget Lifecycle', 'Budget Close Date']}
  status="success"
/>`,
    render: () => (
      <Flex align="flex-start" justify="flex-start">
        <Flex
          direction="column"
          wrap="nowrap"
          align="flex-start"
          justify="flex-start"
          className="m-r-sp700"
        >
          <Alert
            message="The budget has been approved and is ready to be finalized."
            status="success"
          />
          <Alert message="You do not have permission to perform this action." status="error" />
          <Alert
            message="This invoice contains line items which have been flagged."
            status="warning"
          />
          <Alert message="You are the creator of this matter." status="info" />
          <Alert
            message="A review has been started and is waiting for user action."
            status="default"
          />
        </Flex>
        <Flex
          direction="column"
          wrap="nowrap"
          align="flex-start"
          justify="flex-start"
          className="m-r-sp700"
        >
          <Alert
            message="The following fields were successfully updated"
            items={['Budget Final Amount', 'Budget Lifecycle', 'Budget Close Date']}
            status="success"
          />
          <Alert
            message="You are missing values for the following required fields"
            items={['Matter Name', 'Country', 'Cost Code']}
            status="error"
          />
          <Alert
            message="This invoice contains line items which have been flagged for the following"
            items={['Timekeeper Validation', 'Invalid Client Matter Id', 'Invalid Tax Type']}
            status="warning"
          />
          <Alert
            message="The following users have access to this matter"
            items={['Ian Jabour', 'Darren Dahl', 'Sylvia Yu', 'Cameron Cairns']}
            status="info"
          />
          <Alert
            message="Once enabled via the console you will be able to"
            items={[
              'Add users with a valid company email to the system',
              'Create Vendor Surveys',
              'Create up to 15 teams'
            ]}
            status="default"
          />
        </Flex>
      </Flex>
    )
  },
  category: CATEGORY.INFO,
  path: 'components/Core/Alert/Alert'
})

export default Alert
