import s from './AvatarList.scss'
import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'
import { Avatar } from 'simple-core-ui'
import cn from 'classnames'
import { CSSProperties } from 'react'

interface Props {
  limit?: number
  entries: Array<{ id?: number; label: string }>
  className?: string
  avatarStyles?: CSSProperties
  wrapperStyles?: CSSProperties
  size?: 'xs' | 'sm' | 'md'
}

const AvatarList = ({
  entries,
  limit = Infinity,
  className,
  avatarStyles,
  wrapperStyles,
  size = 'sm'
}: Props): JSX.Element => {
  const visibleEntries = entries.slice(0, limit)
  const hiddenEntries = entries.slice(limit)

  return (
    <div style={wrapperStyles} data-tip={entries.map(entry => entry.label).join(', ')}>
      <span className={cn(s.listWrapper, className)}>
        {visibleEntries.map((entry, index) => (
          <Avatar
            className={cn(s.avatar, {
              [s.medium]: size === 'md',
              [s.small]: size === 'sm',
              [s.xsmall]: size === 'xs'
            })}
            styles={avatarStyles}
            key={entry.id || index}
            initials={entry.label}
          />
        ))}
        {hiddenEntries.length > 0 && <span className={s.more}> +{hiddenEntries.length}</span>}
      </span>
    </div>
  )
}

registration.register({
  name: 'AvatarList',
  description:
    'This component should be utilized to represent a given list of users, displaying their initials up front and their full name on hover',
  props: [
    {
      name: 'entries',
      optional: false,
      type: 'array',
      note: 'An array of entries objects of the form { id, label }'
    },
    {
      name: 'limit',
      optional: true,
      type: 'number',
      note:
        'A number representing the number of visible avatars. Atfer this limit a "+x" will be shown. If not present all the avatars will be shown.'
    },
    {
      name: 'className',
      type: 'string',
      optional: true,
      note: 'Custom class to be applied to the component.'
    },
    {
      name: 'avatarStyles',
      type: 'object',
      optional: true,
      note: 'Custom styles to be applied to the individual avatar.'
    }
  ],
  example: {
    literal: `<AvatarList
    limit={3}
    entries={[
      {
        id: 1,
        label: 'Karen Moor'
      },
      {
        id: 2,
        label: 'Darren Dahl'
      },
      {
        id: 3,
        label: 'Andrei Stanciu'
      },
      {
        id: 4,
        label: 'Chris Wong'
      },
      {
        id: 5,
        label: 'Alin Treznai'
      }
    ]}
  />`,
    render: () => (
      <AvatarList
        limit={3}
        entries={[
          {
            id: 1,
            label: 'Karen Moor'
          },
          {
            id: 2,
            label: 'Darren Dahl'
          },
          {
            id: 3,
            label: 'Andrei Stanciu'
          },
          {
            id: 4,
            label: 'Chris Wong'
          },
          {
            id: 5,
            label: 'Alin Treznai'
          }
        ]}
      />
    )
  },
  category: CATEGORY.TYPE,
  path: 'components/Core/AvatarList/AvatarList'
})

export default AvatarList
