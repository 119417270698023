import { useState } from 'react'
import { IoMdClose, IoMdCheckmark } from 'react-icons/io'

import { Popable, If, Button, OutsideClickContainer } from 'simple-core-ui'

import s from './Xeditable.scss'

const Xeditable = ({
  position,
  isOpen,
  value,
  placeholder,
  title,
  closeCb,
  openCb,
  setValueCb,
  renderForm,
  renderContent,
  isBtnGroupExcluded,
  isDisabled,
  openOnClick,
  styles,
  error,
  readOnly,
  testid,
  isClosableByOutsideClick = true,
  isPortal,
  type
}) => {
  const [coords, setCoords] = useState(null)

  const togglePopover = e => {
    isPortal && updateTooltipCoords(e.target)
    isOpen ? closeCb(e) : openCb(e)
  }

  const updateTooltipCoords = button => {
    if (typeof button.getBoundingClientRect !== 'function') return

    const rect = button.getBoundingClientRect()
    setCoords({
      left: rect.x + rect.width / 2, // add half the width of the button for centering
      top: rect.y + window.scrollY // add scrollY offset, as soon as getBountingClientRect takes on screen coords
    })
  }

  const condition = type === 'currency' ? Boolean(value) && value.amount !== '' : Boolean(value)
  const renderedContent = (
    <If condition={condition} fallback={placeholder}>
      {renderContent(value, openCb, closeCb)}
    </If>
  )

  return readOnly ? (
    renderedContent
  ) : (
    <OutsideClickContainer
      closeComponent={isClosableByOutsideClick && !isPortal && isOpen ? closeCb : () => {}}
    >
      <Popable
        isPortal={isPortal}
        coords={coords}
        updateTooltipCoords={updateTooltipCoords}
        position={position}
        styles={styles}
        content={
          <section className={s.popableWrapper}>
            <If condition={title}>
              <h6 className={s.title}>{title}</h6>
            </If>
            <section className={s.content}>
              {renderForm(setValueCb, closeCb)}
              <If condition={error}>
                <span className={s.error}>{error}</span>
              </If>
              <If condition={!isBtnGroupExcluded}>
                <section className={s.footer}>
                  <Button
                    style={{ height: '2em' }}
                    onClick={setValueCb}
                    disabled={isDisabled}
                    testid={`${testid}-confirm-button`}
                  >
                    <IoMdCheckmark />
                  </Button>
                  <Button style={{ height: '2em' }} onClick={closeCb} isSecondary>
                    <IoMdClose />
                  </Button>
                </section>
              </If>
            </section>
          </section>
        }
        isOpen={isOpen}
        openOnHover={false}
      >
        <If condition={openOnClick} fallback={renderedContent}>
          <span onClick={togglePopover} data-testid={testid}>
            {renderedContent}
          </span>
        </If>
      </Popable>
    </OutsideClickContainer>
  )
}

Xeditable.defaultProps = {
  openOnClick: true
}

export default Xeditable
