import { useEffect, useRef, memo } from 'react'
import { createPortal } from 'react-dom'

const Portal = ({ children }) => {
  const el = useRef(null)
  if (!el.current) el.current = document.createElement('div')

  const mount = document.getElementById('portal-root')

  useEffect(() => {
    const { current } = el

    mount && mount.appendChild(current)
    return () => {
      mount && mount.removeChild(current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return mount ? createPortal(children, el.current) : children
}

export default memo(Portal)
