import { MutableRefObject, useEffect, useRef } from 'react'

interface Params {
  firstRenderCb?(): void
  reRenderCb?(): void
}

/**
 * @param params {Object} Object with `firstRenderCb` and `reRenderCb`. Make sure to wrap those in useCallback
 * @returns MutableRefObject<boolean>
 */
const useFirstRender = ({ firstRenderCb, reRenderCb }: Params): MutableRefObject<boolean> => {
  const firstRender = useRef(true)

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      firstRenderCb?.()
    } else {
      reRenderCb?.()
    }
  }, [firstRenderCb, reRenderCb])

  return firstRender
}

export default useFirstRender
