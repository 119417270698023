import { CSSProperties, Component, ChangeEvent } from 'react'

import { Checkbox } from 'simple-core-ui'

import registration from 'simple-core-ui/docs/registration'
import { CATEGORY } from 'simple-core-ui/docs/constants'

type Size = 'sm' | 'md' | 'lg'
interface Props {
  isChecked: boolean
  styles?: CSSProperties
  hasWhiteBorder?: boolean
  hasGreyBorder?: boolean
  isSelectable?: boolean
  disabled?: boolean
  checkIconClassName?: string
  className?: string
  size?: Size
  testId?: string
  cb?(): void
}
interface State {
  isChecked: boolean
}

class CheckboxContainer extends Component<Props, State> {
  state = {
    isChecked: this.props.isChecked || false
  }
  static getDerivedStateFromProps(nextProps: Props, prevState: State) {
    return typeof nextProps.isChecked === 'boolean' && nextProps.isChecked !== prevState.isChecked
      ? { isChecked: nextProps.isChecked }
      : null
  }
  triggerCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation()
    this.setState({ isChecked: event.target.checked }, () => {
      this.props.cb?.()
    })
  }
  render() {
    return (
      <Checkbox
        {...this.state}
        styles={this.props.styles}
        hasWhiteBorder={this.props.hasWhiteBorder}
        hasGreyBorder={this.props.hasGreyBorder}
        size={this.props.size}
        triggerCheckbox={this.triggerCheckbox}
        className={this.props.className}
        checkIconClassName={this.props.checkIconClassName}
        isSelectable={this.props.isSelectable}
        disabled={this.props.disabled}
        testId={this.props.testId}
      />
    )
  }
}

registration.register({
  name: 'CheckboxContainer',
  description: 'A checkbox component that can be used as a controlled input.',
  props: [
    {
      name: 'isChecked',
      optional: true,
      type: 'boolean',
      note:
        'A boolean prop that will control the state of the container. This does not need to be passed in unless outside logic should controll this input.'
    },
    {
      name: 'hasWhiteBorder',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that will render a white border around the checkbox.'
    },
    {
      name: 'hasGreyBorder',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that will render a grey border around the checkbox.'
    },
    {
      name: 'isSelectable',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that will allow to render the checkbox or not.'
    },
    {
      name: 'disabled',
      optional: true,
      type: 'boolean',
      note: 'A boolean prop that disable the checkbox.'
    },
    {
      name: 'checkIconClassName',
      optional: true,
      type: 'string',
      note: 'A prop that define className for check icon of the checkbox.'
    },
    {
      name: 'className',
      optional: true,
      type: 'string',
      note: 'A prop that define className for the checkbox.'
    },
    {
      name: 'cb',
      optional: true,
      type: 'function',
      note: 'A callback function that will be triggered anytime the checkbox is clicked/toggled.'
    },
    {
      name: 'size',
      optional: true,
      type: 'sm | md | lg',
      defaultValue: 'lg',
      note: 'The size of the checkbox to be rendered.'
    }
  ],
  example: {
    literal: `
const SizedBoxes = ({size}: {size: Size}) => (
  <section style={{ textAlign: 'center' }}>
    <h3>{{sm: 'Small', md: 'Medium', lg: 'Large'}[size]}</h3>
    <div style={{ display: 'inline-flex', padding: '1em' }}>
      <CheckboxContainer
        cb={() => { console.log('Checkbox has been toggled!') }} // eslint-disable-line
        size={size}
        isChecked
      />
    </div>
    <div style={{ background: 'teal', display: 'inline-flex', padding: '1em' }}>
      <CheckboxContainer
        cb={() => { console.log('Checkbox has been toggled!') }} // eslint-disable-line
        hasWhiteBorder
        size={size}
        isChecked={false}
      />
    </div>
  </section>
)

return (
  <>
    {['sm', 'md', 'lg'].map((size, key) => <SizedBoxes size={size} key={key} />)}
  </>
)`.trim(),
    render: () => {
      const SizedBoxes = ({ size }: { size: Size }) => (
        <section style={{ textAlign: 'center' }}>
          <h3>{{ sm: 'Small', md: 'Medium', lg: 'Large' }[size]}</h3>
          <div style={{ display: 'inline-flex', padding: '1em' }}>
            <CheckboxContainer
              isChecked
              cb={() => {
                // eslint-disable-next-line no-console
                console.log('Checkbox has been toggled!')
              }} // eslint-disable-line
              size={size}
            />
          </div>
          <div style={{ background: 'grey', display: 'inline-flex', padding: '1em' }}>
            <CheckboxContainer
              isChecked={false}
              cb={() => {
                // eslint-disable-next-line no-console
                console.log('Checkbox has been toggled!')
              }} // eslint-disable-line
              hasWhiteBorder
              size={size}
            />
          </div>
        </section>
      )

      return (
        <>
          {(['sm', 'md', 'lg'] as Array<Size>).map((size, key: number) => (
            <SizedBoxes size={size} key={key} />
          ))}
        </>
      )
    }
  },
  category: CATEGORY.FORM,
  path: 'containers/Core/CheckboxContainer/CheckboxContainer'
})

export default CheckboxContainer
