import * as React from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import debounce from 'debounce-promise'
import get from 'lodash/get'
import partial from 'lodash/partial'
import noop from 'lodash/noop'

import { ConditionalRender, Input } from 'simple-core-ui'
import { SECONDARY_TYPES } from 'simple-core-ui/utils/constants'

const debounceLoadOptions = loadOptions => debounce(loadOptions, 500)

const listTextContent = (
  changeCb,
  setValueCb,
  value,
  isDisabled,
  optionsProp,
  SelectComponent,
  secondaryType,
  secondaryStyles = {}
) => (
  <React.Fragment>
    <SelectComponent
      name={`xeditable-list-${secondaryType}-type`}
      isDisabled={isDisabled}
      value={get(value, 'option', null)}
      onChange={option => {
        const currentValue = value || {}
        changeCb({ ...currentValue, option })
      }}
      {...optionsProp}
      defaultOptions
      isClearable
    />
    <Input
      onChangeCb={event => {
        const currentValue = value || {}
        const text = event.currentTarget.value
        changeCb({ ...currentValue, text })
      }}
      onEnterCb={secondaryType === 'textarea' ? noop : setValueCb}
      isDisabled={isDisabled}
      text={get(value, 'text', '')}
      type={secondaryType}
      style={{ marginTop: '1em', ...secondaryStyles }}
      hasSpellcheck={secondaryType === 'textarea'}
    />
  </React.Fragment>
)

const XeditableForm = ({
  changeCb,
  value,
  isDisabled,
  type,
  options,
  setValueCb,
  isClearable,
  testid
}) => {
  const [SelectComponent, optionsProp] =
    typeof options === 'function'
      ? [AsyncSelect, { loadOptions: debounceLoadOptions(options) }]
      : [Select, { options }]

  const listTextContentPartial = partial(
    listTextContent,
    changeCb,
    setValueCb,
    value,
    isDisabled,
    optionsProp,
    SelectComponent
  )

  return (
    <ConditionalRender
      conditions={[
        {
          condition: ['text', 'number', 'url', 'email', 'phone'].includes(type),
          content: (
            <Input
              ariaLabel={`${testid}-${type}`}
              onChangeCb={event => changeCb(event.currentTarget.value)}
              onEnterCb={setValueCb}
              isDisabled={isDisabled}
              text={value}
              type={type}
            />
          )
        },
        {
          condition: type === 'textarea',
          content: (
            <Input
              onChangeCb={event => changeCb(event.currentTarget.value)}
              isDisabled={isDisabled}
              text={value}
              type={type}
              style={{ height: '150px' }}
              hasSpellcheck
            />
          )
        },
        {
          condition: type === 'list',
          content: (
            <SelectComponent
              name="xeditable-list-type"
              isDisabled={isDisabled}
              value={value}
              onChange={changeCb}
              {...optionsProp}
              defaultOptions
              isClearable={isClearable}
            />
          )
        },
        ...SECONDARY_TYPES.map(secondaryType => ({
          condition: type === `list-${secondaryType}`,
          content: listTextContentPartial(
            secondaryType,
            secondaryType === 'textarea' ? { height: '150px' } : {}
          )
        }))
      ]}
    />
  )
}

XeditableForm.defaultProps = {
  options: []
}

export default XeditableForm
