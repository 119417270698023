import classNames from 'classnames'

import s from './EbPanel.scss'

import { ErrorBoundaryContainer } from 'simple-core-ui'
import { If } from 'simple-core-ui/components'

const EbPanel = ({
  title,
  subtitle,
  className = '',
  headerClassName = '',
  children,
  actions = [],
  rightActions = [],
  leftActions = [],
  isClosed,
  isError,
  isBodyOnly,
  styles = {},
  bodyClassName = '',
  breadcrumbs
}) => {
  return (
    <div className={`box ${className}`} style={styles}>
      <If condition={!isBodyOnly}>
        <header
          className={classNames(`box-header ${headerClassName}`, {
            [s.closedPanel]: isClosed
          })}
        >
          <div>
            {breadcrumbs && <p className={s.breadcrumbs}>{breadcrumbs}</p>}
            {title && (
              <h2 className={s.title}>
                {title}
                {subtitle && <p className={s.subtitle}>{subtitle}</p>}
              </h2>
            )}
          </div>
          <span className={s.leftActions}>{leftActions.map(el => el)}</span>
          <span className={s.actions}>{actions.map(el => el)}</span>
          <span className={s.rightActions}>{rightActions.map(el => el)}</span>
        </header>
      </If>
      <main
        className={classNames('box-content', bodyClassName, {
          [s.isBodyOnly]: isBodyOnly
        })}
        style={styles}
      >
        <ErrorBoundaryContainer isError={isError}>
          {(isError, message) => (isError ? message : children)}
        </ErrorBoundaryContainer>
      </main>
    </div>
  )
}

export default EbPanel
