import ReactTooltip from 'react-tooltip'

import TableHeadCheckbox from './TableHeadCheckbox'
import TableHeadAvatar from './TableHeadAvatar'
import TableHeadCell from './TableHeadCell'
import TableHeadActions from './TableHeadActions'
import TableHeadBorder from './TableHeadBorder'
import TableColumnExpand from './TableHeadExpand'
import s from './TableHead.scss'

const TableHead = ({
  columns,
  avatarHeader,
  hasAvatar,
  hasActions,
  actions = [],
  hasBorder,
  isEmpty,
  sortTable,
  allRowsSelected,
  selectAllRows,
  checkboxSize,
  showBulkSelectAll,
  expandable,
  alwaysShowActions
}) => (
  <thead className={s.container}>
    <tr>
      {selectAllRows && (
        <TableHeadCheckbox
          isVisible={!isEmpty}
          allRowsSelected={allRowsSelected}
          selectAllRows={selectAllRows}
          checkboxSize={checkboxSize}
          showCheckbox={showBulkSelectAll}
        />
      )}
      {expandable && <TableColumnExpand />}
      {hasAvatar && <TableHeadAvatar avatarHeader={avatarHeader} />}
      {columns.map(({ columnKey, content, style = {}, isSortable, isDesc }, idx) => (
        <TableHeadCell
          content={content}
          sortColumn={() => {
            if (isSortable) {
              ReactTooltip.hide()
              sortTable({ columnKey, isDesc: !isDesc })
            }
          }}
          style={{ cursor: isSortable ? 'pointer' : 'default', ...style }}
          isSortable={isSortable}
          columnKey={columnKey}
          isDesc={isDesc}
          key={idx}
        />
      ))}
      {(hasActions || !!actions.length) && (
        <TableHeadActions alwaysShowActions={alwaysShowActions} />
      )}
      {hasBorder && <TableHeadBorder />}
    </tr>
  </thead>
)

export default TableHead
