import { coreThemes } from './utils/constants'
import { fes, renderWithLineBreaks } from './utils/formatting'

import Styleguide from './docs/Styleguide/Styleguide'
import SessionExpiration from './SessionExpiration'

import * as DESIGN from './styles/system'

// We put this on window to provide legacy JS access to values...
window.DESIGN = DESIGN

export * as markdown from 'docs/markdown'
export * as system from 'docs/system'

export * from './components'
export * from './containers'
export * from './context'
export * from './services'
export * from './serializers'
export * from './utils'
export * from './styles/cssInJs'
export * from './hocs'
export * from './hooks'
export * from './icons'

export { coreThemes, Styleguide, fes, renderWithLineBreaks, DESIGN, SessionExpiration }
